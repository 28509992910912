@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family:PlusJakartaSans-Bold;
  src: url("./assets/fontfamily/PlusJakartaSans-Bold.ttf");
}
@font-face {
  font-family:PlusJakartaSans-Light;
  src: url("./assets/fontfamily/PlusJakartaSans-Light.ttf");
}
@font-face {
  font-family:PlusJakartaSans-Medium;
  src: url("./assets/fontfamily/PlusJakartaSans-Medium.ttf");
}
@font-face {
  font-family:PlusJakartaSans-Regular;
  src: url("./assets/fontfamily/PlusJakartaSans-Regular.ttf");
}
@font-face {
  font-family:PlusJakartaSans-SemiBold;
  src: url("./assets/fontfamily/PlusJakartaSans-SemiBold.ttf");
}

.custom-shadow{
    box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
}


  .f-f-b{
    font-family:PlusJakartaSans-Bold;
  }
  .f-f-li{
    font-family:PlusJakartaSans-Light;
  }
  .f-f-m{
    font-family:PlusJakartaSans-Medium;
  }
  .f-f-r{
    font-family:PlusJakartaSans-Regular;
  }
  .f-f-sm{
    font-family:PlusJakartaSans-SemiBold;
  }